import React, { useEffect, useState } from "react";
import Sidebar from "../Partials/Sidebar";
import Header from "../Partials/Header";
import axios from "axios";
import config from "../config";
import {
  IconDeviceMobile,
  IconPlus,
  IconScan,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import ClipLoader from "react-spinners/ClipLoader";
import { useSidebar } from "../Providers/SidebarProvider";
import Modal from "../components/Modal";

const Devices = () => {
  const user = JSON.parse(window.localStorage.getItem("user_data"));
  const { isExpanded, setExpanded } = useSidebar();
  const [isLoading, setLoading] = useState(true);
  const [triggerLoading, setTriggerLoading] = useState(true);
  const [devices, setDevices] = useState([]);
  const [device, setDevice] = useState(null);
  const [raw, setRaw] = useState(null);

  const [qrData, setQrData] = useState(false);
  const [isLoadingQr, setLoadingQr] = useState(false);

  const [isAdding, setAdding] = useState(false);
  const [isDeleting, setDeleting] = useState(false);
  const [isDeletingProc, setDeletingProc] = useState(false);

  const [modalContactsFromDevice, setModalContactsFromDevice] = useState(false);
  const [contactsFromDevice, setContactsFromDevice] = useState([]);
  const [selectAll, setSelectAll] = useState(true);

  useEffect(() => {
    if (isLoading && triggerLoading && user !== null) {
      setTriggerLoading(false);
      axios
        .post(`${config.baseUrl}/api/user/device`, {
          token: user.token,
        })
        .then((response) => {
          let res = response.data;
          setLoading(false);
          setRaw(res.devices);
          setDevices(res.devices);
        });
    }
  }, [isLoading, triggerLoading, user]);

  useEffect(() => {
    if (isLoadingQr && user !== null) {
      setLoadingQr(false);

      axios
        .post(`${config.baseUrl}/api/device/connect`, {
          token: user.token,
          device_id: device.id ?? null,
        })
        .then((response) => {
          let res = response.data;
          setQrData(res.qrCodeUrl);
        })
        .catch((err) => {
          setQrData(false);
          console.error(err);
        });
    }
  }, [isLoadingQr, user]);

  useEffect(() => {
      let updatedItems = contactsFromDevice.map(val => {
        return {...val, active: selectAll}
      })
      setContactsFromDevice(updatedItems)
  }, [selectAll]);

  const get_contacts_from_device = (device) => {
    setDevice(device);
    setModalContactsFromDevice(true);
    axios
      .post(`${config.baseUrl}/api/contact/get-contacts-from-device`, {
        device_id: device.id,
        token: user.token,
      })
      .then((response) => {
        setContactsFromDevice(
          response.data.map((val) => {
            val.active = true;
            return val;
          })
        );
      });
  };

  const import_contacts_from_device = () => {
    axios
      .post(`${config.baseUrl}/api/contact/import-contacts-from-device`, {
        token: user.token,
        contacts: contactsFromDevice.filter(val => val.active)
      })
      .then((response) => {
        window.location.reload()
      });
  };

  const del = (e) => {
    e.preventDefault();
    setDeletingProc(true);
    axios
      .post(`${config.baseUrl}/api/device/remove`, {
        id: device.id,
        token: user.token,
      })
      .then((response) => {
        let res = response.data;
        setDeletingProc(false);
        setDeleting(false);
        setLoading(true);
        setTriggerLoading(true);
      });
  };

  return (
    <>
      <Header />
      <Sidebar active={null} />
      <div
        className={`absolute top-20 right-0 ${
          isExpanded ? "w-9/12" : "w-11/12"
        } mobile:w-full p-4 ps-0 mobile:ps-4`}
      >
        <div className="flex items-center gap-2 mt-4">
          <div className="text-slate-500">Pengaturan</div>
          <div className="text-slate-500">/</div>
          <div className="text-slate-700">Daftar Perangkat</div>
        </div>

        <div className="bg-white shadow-[0px_16px_32px_rgba(0,0,0,0.05)] p-8 px-8 rounded-lg mt-8">
          <div className="flex items-center gap-4 mb-4">
            <div className="text-xl font-bold flex grow">Daftar Perangkat</div>
            <button
              className="bg-green-100 text-green-500 p-2 px-4 rounded-lg flex gap-2 items-center text-sm"
              onClick={() => {
                setAdding(true);
                setLoadingQr(true);
                setQrData(null);
              }}
            >
              <IconPlus />
              Tambahkan Perangkat
            </button>
          </div>

          {isLoading ? (
            <>
              <div className="w-full h-12 bg-slate-200"></div>
              <div className="flex gap-4 mt-2">
                <div className="flex grow h-10 bg-slate-200"></div>
                <div className="flex grow h-10 bg-slate-200"></div>
                <div className="flex grow h-10 bg-slate-200"></div>
                <div className="flex grow h-10 bg-slate-200"></div>
              </div>
              <div className="flex gap-4 mt-2">
                <div className="flex grow h-10 bg-slate-200"></div>
                <div className="flex grow h-10 bg-slate-200"></div>
                <div className="flex grow h-10 bg-slate-200"></div>
              </div>
              <div className="flex gap-4 mt-2">
                <div className="flex grow h-10 bg-slate-200"></div>
                <div className="flex grow h-10 bg-slate-200"></div>
                <div className="flex grow h-10 bg-slate-200"></div>
                <div className="flex grow h-10 bg-slate-200"></div>
              </div>
            </>
          ) : (
            <>
              <table className="table w-full">
                <thead>
                  <tr className="border-b text-left">
                    <th className="py-4 text-slate-700">Label</th>
                    <th className="py-4 text-slate-700">Nomor</th>
                    <th className="py-4 text-slate-700">Status</th>
                    <th className="py-4 text-slate-700">Tindakan</th>
                  </tr>
                </thead>
                <tbody>
                  {devices.map((dev) => (
                    <tr key={dev.id}>
                      <td className="py-4 text-slate-600">{dev.label}</td>
                      <td className="py-4 text-slate-600">{dev.number}</td>
                      <td className="py-4 text-slate-600">{dev.status}</td>
                      <td className="py-4 text-slate-600 flex gap-x-4">
                        {dev.status == "ready" ? (
                          <button
                            className="h-10 px-4 rounded-xl flex gap-2 items-center text-sm justify-center bg-gray-200 text-primary"
                            onClick={() => {
                              get_contacts_from_device(dev);
                            }}
                          >
                            <IconDeviceMobile size={18} />
                            Sinkronkan Kontak dari Perangkat
                          </button>
                        ) : (
                          ""
                        )}
                        {dev.status == "disconnected" ? (
                          <button
                            className="h-10 aspect-square rounded-xl flex items-center justify-center bg-gray-200 text-primary"
                            onClick={() => {
                              setDevice(dev);
                              setAdding(true);
                              setLoadingQr(true);
                              setQrData(null);
                            }}
                          >
                            <IconScan size={18} />
                          </button>
                        ) : (
                          ""
                        )}

                        <button
                          className="h-10 aspect-square rounded-xl flex items-center justify-center bg-gray-200 text-red-500"
                          onClick={() => {
                            setDevice(dev);
                            setDeleting(true);
                          }}
                        >
                          <IconTrash size={18} />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </div>
      </div>

      {isDeleting && (
        <div
          id="overlay"
          className="fixed top-0 left-0 right-0 bottom-0 z-10 bg-slate-700 backdrop-blur-sm bg-opacity-75 flex items-center justify-center"
          onClick={(e) => {
            if (e.target.getAttribute("id") === "overlay") {
              setDeleting(false);
            }
          }}
        >
          {isDeletingProc ? (
            <form className="bg-white rounded-lg p-8 w-4/12 mobile:w-10/12 flex flex-col gap-4 items-center justify-center">
              <ClipLoader
                loading={isDeletingProc}
                color={config.primaryColor}
              />
              <div>Menghapus perangkat</div>
            </form>
          ) : (
            <form
              className="bg-white rounded-lg p-8 w-4/12 mobile:w-10/12 flex flex-col gap-4"
              onSubmit={del}
            >
              <div className="flex items-center gap-4">
                <div className="text-slate-700 text-lg font-bold flex grow">
                  Hapus Perangkat "{device.label}"
                </div>
                <div
                  className="h-12 aspect-square rounded-full flex items-center justify-center cursor-pointer border"
                  onClick={() => setDeleting(false)}
                >
                  <IconX />
                </div>
              </div>
              <div>
                Yakin ingin menghapus perangkat <b>{device.label}</b> dengan
                nomor <b>{device.number}</b>?
              </div>
              <div className="flex items-center justify-end gap-4 mt-4 pt-4 border-t">
                <button
                  className="p-2 px-4 rounded-lg bg-slate-200"
                  type="button"
                  onClick={() => setDeleting(false)}
                >
                  Batal
                </button>
                <button className="p-2 px-4 rounded-lg bg-red-500 text-white">
                  Hapus
                </button>
              </div>
            </form>
          )}
        </div>
      )}

      {isAdding && (
        <div
          id="overlay"
          className="fixed top-0 left-0 right-0 bottom-0 z-10 bg-slate-700 backdrop-blur-sm bg-opacity-75 flex items-center justify-center"
          onClick={(e) => {
            if (e.target.getAttribute("id") === "overlay") {
              setAdding(false);
            }
          }}
        >
          {qrData === null ? (
            <form className="bg-white rounded-lg p-8 w-4/12 mobile:w-10/12 flex flex-col gap-4 items-center justify-center">
              <ClipLoader
                loading={qrData === null}
                color={config.primaryColor}
              />
              <div>Memuat Kode QR</div>
            </form>
          ) : (
            <form className="bg-white rounded-lg p-8 w-6/12 mobile:w-10/12 flex flex-row gap-8">
              <div className="w-6/12">
                <img
                  src={qrData}
                  alt="qr code"
                  className="w-full aspect-square"
                />
              </div>
              <div className="flex flex-col grow">
                <div className="text-slate-700 font-bold text-xl">
                  Sambungkan ke WhatsApp
                </div>
                <ul className="ps-4 mt-4 text-sm flex flex-col gap-2">
                  <li className="list-disc text-slate-500">
                    Pastikan koneksi ponsel stabil
                  </li>
                  <li className="list-disc text-slate-500">
                    Buka aplikasi whatsapp di ponsel Anda
                  </li>
                  <li className="list-disc text-slate-500">
                    Tekan titik tiga di pojok kanan atas
                  </li>
                  <li className="list-disc text-slate-500">
                    Pilih <span className="font-bold">Perangkat Tertaut</span>
                  </li>
                  <li className="list-disc text-slate-500">
                    Klik tombol{" "}
                    <span className="font-bold">Tautkan Perangkat</span>
                  </li>
                  <li className="list-disc text-slate-500">
                    Lakukan scan kode QR
                  </li>
                </ul>

                <button
                  className="border border-primary text-primary w-full rounded-lg h-12 mt-4"
                  onClick={() => {
                    setQrData(false);
                    setAdding(false);
                  }}
                >
                  Tutup
                </button>
              </div>
            </form>
          )}
        </div>
      )}
      <Modal
        show={modalContactsFromDevice}
        closeModal={() => setModalContactsFromDevice(false)}
      >
        <div className="flex items-center gap-4 text-slate-700 p-4 mobile:h-24 sticky top-0 z-10 bg-white">
          <div className="flex flex-col gap-2 desktop:grow">
            <div className="text-xl mobile:text-sm font-bold text-slate-700">
              Sinkronkan Kontak
            </div>
            <div className="text-sm mobile:text-xs text-slate-500">
              Device : {device?.label ?? "-"} ({device?.number ?? ""})
            </div>
          </div>
          <div className="flex flex-col gap-1 desktop:w-5/12 mobile:grow">
            <div className="desktop:border rounded-lg p-2 mobile:p-0 mobile:border-b w-full">
              <div className="text-xs text-slate-500">Cari kontak :</div>
              <input
                type="text"
                className="h-12 mobile:h-10 flex grow w-full outline-0"
              />
            </div>
          </div>
          <button onClick={import_contacts_from_device} className="bg-primary text-white text-sm font-bold p-2 px-4 rounded-lg">
            Impor Kontak
          </button>
        </div>
        <table className="table min-w-full relative">
          <thead className="sticky top-28 z-10 bg-white">
            <tr className="border-b text-left">
              <th className="py-4 mobile:text-sm text-slate-700">
              <div onClick={() => {
                let updatedItems = contactsFromDevice.map(val => {
                  return {...val, active: contactsFromDevice.some(val => !val.active)}
                })
                setContactsFromDevice(updatedItems)
              }} className="aspect-square rounded flex items-center justify-center cursor-pointer h-6 border">
                  {!contactsFromDevice.some(val => !val.active) ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#198754"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="tabler-icon tabler-icon-check"
                    >
                      <path d="M5 12l5 5l10 -10"></path>
                    </svg>
                  ) : (
                    ""
                  )}
                </div>
              </th>
              <th className="py-4 mobile:text-sm text-slate-700">Nama</th>
              <th className="py-4 mobile:text-sm text-slate-700">
                No. WhatsApp
              </th>
              <th className="py-4 mobile:text-sm text-slate-700">Tipe</th>
            </tr>
          </thead>
          <tbody className="">
            {contactsFromDevice.map((val, index) => {
              return (
                <tr key={val.number}>
                  <td className="py-4 text-slate-700">
                    <div
                      onClick={() => {
                        const updatedItems = contactsFromDevice.map(
                          (val2, index2) => {
                            if (index === index2) {
                              return { ...val2, active: !val2.active };
                            }
                            return val2;
                          }
                        );
                        setContactsFromDevice(updatedItems);
                      }}
                      className="aspect-square rounded flex items-center justify-center cursor-pointer h-6 border"
                    >
                      {val.active ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="#198754"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="tabler-icon tabler-icon-check"
                        >
                          <path d="M5 12l5 5l10 -10"></path>
                        </svg>
                      ) : (
                        ""
                      )}
                    </div>
                  </td>
                  <td className="py-4 text-slate-700">{val.name}</td>
                  <td className="py-4 text-slate-700">{val.number}</td>
                  <td className="py-4 text-slate-700">
                    {val.is_group ? "Grup" : "Kontak"}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Modal>
    </>
  );
};

export default Devices;

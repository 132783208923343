const config = {
    // baseUrl: 'http://127.0.0.1:8000',
    whatsappUrl: 'http://127.0.0.1:2024',
    baseUrl: 'https://broadcast-api.zainzo.com',
    primaryColor: "#198754",
    google_client_id: "994799062541-4t89ihvo8d1sbotk8bbhprfp9svj4n1t.apps.googleusercontent.com",
    google_analytics_tracking_code: "UA-107986295-1",
    whatsapp_admin: "628159830008",
}

export default config
const Modal = ({ children, show = false, closeModal }) => {
  return (
    <>
      {show ? (
        <div
          id="overlay"
          className="fixed top-0 left-0 right-0 bottom-0 z-10 bg-slate-700 backdrop-blur-sm bg-opacity-75 flex items-center justify-center"
          onClick={(e) => {
            if (e.target.getAttribute("id") === "overlay") {
              closeModal()
            }
          }}
        >
          <div className="bg-white rounded-lg px-2 w-8/12 mobile:w-full overflow-hidden max-h-[90vh] overflow-y-auto">
            {children}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Modal;
